<template>
    <ion-page>

          <ion-content>
            <ion-grid class="google">              
              <ion-row class="google ion-align-items-center ion-justify-content-center">
                <ion-button v-on:click="loginWithGoogle" fill="outline" size ="large" color="primary" class="login">
                    <ion-icon slot="start" icon="assets/google.svg"/>
                    Login 
                </ion-button>
              </ion-row>
              <ion-row class="google ion-align-items-start ion-justify-content-center">
                <ion-img src="img/icons/android-chrome-192x192.png"/> 
              </ion-row>
            </ion-grid>
          </ion-content>
    </ion-page>
</template>

<script>
    import {    IonPage, IonContent, IonButton,
                IonGrid,IonRow, IonIcon, IonImg

            } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import eventBus from "@/event-bus";
    import {setInitDates, getInitFilters} from '@/utilities/services/init_services'
    export default defineComponent({
        name : "Login",
        components: {  IonPage, IonContent, IonButton,
            IonGrid,IonRow, IonIcon, IonImg
            },
        data() {
            return {
                ges : false
            }
        },
        methods: {
            async loginWithGoogle() {
                const self = this
                
                this.$firebase.auth().setPersistence(this.$firebase.auth.Auth.Persistence.LOCAL)
                    .then(function() {
                        const provider = new self.$firebase.auth.GoogleAuthProvider();
                        provider.setCustomParameters({
                            prompt: 'select_account'
                        });
                        self.$firebase
                            .auth()
                            .signInWithPopup(provider)
                            .then( (usr) => {
                                /** Hay usuario, obtenemos token y fijamos rol y datos del usuario  */
                                window.fake_console('Login 48: '+JSON.stringify(usr.user.email))
                                self.$firebase.auth().currentUser.getIdToken(true).then(async (idToken) =>{
                                    self.$http.defaults.headers['Authorization'] = 'Bearer '+idToken
                                    self.$fechas= await setInitDates()
                                    await getInitFilters()
                                    const supRequest = await self.$http.get('/check')
                                    const roles = supRequest.data
                                    roles['who'] = usr.displayName
                                    window.fake_console('Login 61 ', JSON.stringify(roles))
                                    eventBus().emitter.emit('user-logged',roles);   
                                    self.$router.replace(self.$deeplink !=undefined ? self.$deeplink : '/gestor/list') 
                                })
                            })
                            .catch(err => { alert('en el login '+JSON.stringify(err.message)); });
                    })
                    .catch(function(error){
                        window.fake_console('Login 73 (Error) : ',JSON.stringify(error))
                    })
            }
        }
})

</script>

<style scoped>
    ion-grid.google {
        height: 70%;
        margin-top: 20%;
        margin-bottom: 10%;
        width: 90%;
    }
    ion-row.google {
        height: 50%;
    }
</style>